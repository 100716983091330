import { combineReducers } from "redux";
import summary from "./summary/reducers";
import waiting from "./invoice/waitingReducers";
import paid from "./invoice/paidReducer";
import table from "./invoice/tableReducer";

const rootReducers = combineReducers({
  summary: summary,
  waiting: waiting,
  paid: paid,
  table: table,
});

export default rootReducers;
