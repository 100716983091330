import React, { useState } from "react";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import DataTable from "../../components/DataTable/DataTable";
import Button from "../../components/Button/Button";
import "./Summary.css";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import handleCurrency from "../../components/CurrencyFormat";
import Loading from "../../components/Loading";
import axiosGet from "../../helpers/axiosGet";
import { setPaymentLink } from "../../store/summary/actions";

export default function Summary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const summary = useSelector((state) => state.summary.dataSummary);

  const [loading, setLoading] = useState(false);

  const handlePaid = () => {
    window.scrollTo(0, 0);
    setLoading(true);

    const invoiceNumbers = summary.invoiceList.map((value) => {
      return value.invoiceNumber;
    });

    axiosGet({
      url: `/invoice/payment?invoiceNumbers=${invoiceNumbers}`,
      callback: (res) => {
        setLoading(false);
        let redirect = res.data;
        window.open(redirect);
        dispatch(setPaymentLink(redirect));
        history.push("/paid");
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  return (
    <>
      <Jumbotron />

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-5">
            <p className="link-text">Summary</p>
            <hr className="divider" />
          </div>

          <div className="mt-5 p-4 border rounded text-grey">
            <DataTable invoices={summary.invoiceList} active="summary" />
          </div>

          <div className="mt-5">
            <div className="total-card">
              <div>
                <p>Ringkasan Pembayaran</p>
              </div>
              <div>
                <p>Total Invoice</p>
                <p>{summary.invoiceList.length} </p>
              </div>
              <div>
                <p>Total Pembayaran</p>
                <p>(IDR) {handleCurrency(summary.totalAmount)}</p>
              </div>
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-between">
            <Link to="/invoice" onClick={() => window.scrollTo(0, 0)}>
              <Button text="BACK" secondary back />
            </Link>

            <Button text="PAID" onClick={handlePaid} />
          </div>
        </>
      )}
    </>
  );
}
