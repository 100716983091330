import * as uniqId from "uniqid";

export default function generateCorrelationId(totalLength = 24) {
  const variable = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890".split(
    ""
  );
  let code = uniqId();
  while (true) {
    if (code.length < totalLength) {
      code = `${code}${
        variable[Math.ceil(Math.random() * (variable.length - 1))]
      }`;
    } else {
      return code;
    }
  }
}
