import React from "react";
import { MDBIcon } from "mdbreact";

const styles = {
  root: {
    borderRadius: "30px",
    color: "white",
    padding: "4px 12px",
    background: "#addfff",
    fontWeight: "bold",
  },
  paid: {
    background: "grey",
  },
  cancel: {
    background: "red",
  },
};

export default function StatusPill({ value }) {
  const bgColor = (value) => {
    switch (value) {
      case 100:
        return { ...styles.root, ...styles.paid };
      case 300:
        return { ...styles.root, ...styles.cancel };
      default:
        return styles.root;
    }
  };

  return (
    <div style={bgColor(value)}>
      <MDBIcon className="pr-2" icon="check" />
      {(() => {
        switch (value) {
          case 100:
            return "PAID";
          case 300:
            return "CANCEL";
          default:
            return "SUBMITTED";
        }
      })()}
    </div>
  );
}
