import React from "react";
import { Link } from "react-router-dom";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import Logo from "../img/polismall.png";

export default function Header() {
  return (
    <MDBNavbar className="z-depth-0">
      <MDBNavbarBrand>
        <Link to="/invoice">
          <img src={Logo} alt="polismall" style={{ height: 30 }}></img>
        </Link>
      </MDBNavbarBrand>
    </MDBNavbar>
  );
}
