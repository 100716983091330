import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody, MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";
import "./DataTable.css";
import handleProductCode from "../ProductCode";
import handleCurrency from "../CurrencyFormat";
import moment from "moment";

export default function DataTable({ invoices, active, onChecked }) {
  return (
    <MDBTable hover responsive>
      <MDBTableHead>
        <tr>
          {active === "waiting" && <th></th>}

          <th style={{ minWidth: 210 }}>
            <div>Invoice Number</div>
            <div>Placing Slip Number</div>
            <div>Policy Number</div>
            <div>Reference Number</div>
          </th>
          <th>Product Code</th>
          <th style={{ minWidth: 250 }}>
            Company Name <br />
            Insured <br />
            Insurer
          </th>
          <th style={{ minWidth: 210 }}>
            Policy Issue Date <br />
            Invoice Issue Date <br />
            Schedule Payment Date <br />
            Payment Date
          </th>
          <th style={{ minWidth: 140 }}>
            Sum Insured <br />
            Premium
          </th>

          {active !== "summary" && <th>Action</th>}
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {invoices.map((inv, index) => {
          return (
            <tr key={inv.id}>
              {active === "waiting" && (
                <td>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`tableCheckbox${index}`}
                      onChange={(e) => onChecked(e, inv)}
                      checked={inv.checked}
                    />
                    <label
                      className="custom-control-label font-weight-bold"
                      htmlFor={`tableCheckbox${index}`}
                    ></label>
                  </div>
                </td>
              )}

              <td>
                <div>{inv.invoiceNumber}</div>
                <div>{inv.placingSlipNo ? inv.placingSlipNo : "-"} </div>
                <div>{inv.policyNumber}</div>
                <div style={{ wordWrap: "break-word" }}>
                  {inv.refNumber ? inv.refNumber : "-"}
                </div>
              </td>
              <td>{handleProductCode(inv.productCode)}</td>
              <td>
                {inv.companyName} <br />
                {inv.insured} <br />
                {inv.insurer}
              </td>
              <td>
                <div>
                  {moment(inv.policyIssueDate).format("DD MMM YYYY HH:mm:ss")}
                </div>
                <div>
                  {moment(inv.invoiceIssueDate).format("DD MMM YYYY HH:mm:ss")}
                </div>
                <div>
                  {moment(inv.schedulePaymentDate).format(
                    "DD MMM YYYY HH:mm:ss"
                  )}
                </div>
                <div>
                  {inv.paymentDate
                    ? moment(inv.paymentDate).format("DD MMM YYYY HH:mm:ss")
                    : "-"}
                </div>
              </td>
              <td>
                <div>
                  {`${inv.currencyCode} ${handleCurrency(inv.sumInsured)}`}
                </div>
                <div>
                  {`${inv.currencyCodeForPremium} ${handleCurrency(
                    inv.premium
                  )}`}
                </div>
              </td>

              {active !== "summary" && (
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <Link
                      to={() => {
                        const encode = btoa(inv.id);
                        return `/invoice/${encode}`;
                      }}
                      className="action-button"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <MDBIcon
                        icon="chevron-right"
                        size="lg"
                        className="white-text"
                      />
                    </Link>
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </MDBTableBody>
    </MDBTable>
  );
}
