import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import Button from "../../components/Button/Button";
import { resetDataSummary } from "../../store/summary/actions";

export default function Paid() {
  const dispatch = useDispatch();
  const history = useHistory();
  const link = useSelector((state) => state.summary.paymentLink);

  const handlePayment = () => {
    window.open(link);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    dispatch(resetDataSummary(true));
    history.push("/invoice");
  };

  return (
    <>
      <Jumbotron />

      <div style={{ marginTop: 150 }}>
        <div className="mt-5 d-flex justify-content-between align-items-start">
          <Button
            text="Back To Invoice List"
            secondary
            onClick={handleBack}
            back
          />
          <Button text="Go To Payment" onClick={handlePayment} />
        </div>
      </div>
    </>
  );
}
