import products from "./ProductCode.json";

export default function ProductCode(value) {
  let name = "";
  products.forEach((product) => {
    if (product.value === value) {
      name = product.name;
    }
  });
  return name;
}
