import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import axiosGet from "../../helpers/axiosGet";
import Loading from "../../components/Loading";
import "./InvoiceDetail.css";
import { MDBRow, MDBCol } from "mdbreact";
import StatusPill from "../../components/StatusPill";
import CardImage from "../../img/card.svg";
import moment from "moment";
import handleCurrency from "../../components/CurrencyFormat";
import Button from "../../components/Button/Button";

export default function InvoiceDetail() {
  const history = useHistory();
  let { id } = useParams();
  const decode = atob(id);

  useEffect(() => {
    axiosGet({
      url: `/invoice/id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setInvoice(res.data);
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
        history.push("/404");
      },
    });
  }, [decode, history]);

  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleDownload = (id, invNumber) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/invoice/id/${id}/download-file/invoice`,
      responseType: "blob",
      callback: (res) => {
        console.log(res);
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        const blob = new Blob([res]);
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", `${invNumber}-Invoice.pdf`);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
    });
  };
  return (
    <>
      <Jumbotron />

      <div className="mt-5">
        <p className="link-text">Invoice Detail</p>
        <hr className="divider" />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-5 status-justify">
            <StatusPill value={invoice.status} />
          </div>

          <div className="mt-5">
            <MDBRow className="create-row-gap">
              <MDBCol md="6">
                <MDBRow className="create-row-gap">
                  <MDBCol md="12">
                    <div className="flat-card">
                      <div className="flat-card-top">
                        <p>Invoice Number</p>
                        <p>{invoice.invoiceNumber}</p>
                      </div>
                      <div className="flat-card-bottom">
                        <div>
                          <p>Placing Slip Number</p>
                          <p>{invoice.placingSlipNo}</p>
                        </div>
                        <div>
                          <p>Policy Number</p>
                          <p>{invoice.policyNumber}</p>
                        </div>
                        <div>
                          <p>Debit Note Number</p>
                          <p>
                            {invoice.debitNoteNo ? invoice.debitNoteNo : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div className="flat-card">
                      <div className="flat-card-top">
                        <p>Company Name</p>
                        <p>{invoice.companyName}</p>
                      </div>
                      <div className="flat-card-bottom">
                        <div>
                          <p>Insured</p>
                          <p>{invoice.insured}</p>
                        </div>
                        <div>
                          <p>Insurer</p>
                          <p>{invoice.insurer}</p>
                        </div>
                        <div>
                          <p>Email Receiver Name</p>
                          <p>
                            {invoice.emailReceiverName
                              ? invoice.emailReceiverName
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <p>Email Receiver</p>
                          <p>
                            {invoice.emailReceiver
                              ? invoice.emailReceiver
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="6">
                <div className="color-card">
                  <img src={CardImage} alt="card" className="card-image" />
                  <div className="color-card-top">
                    <p>Schedule Payment Date</p>
                    <p>
                      {moment(invoice.schedulePaymentDate).format(
                        "DD MMM YYYY HH:mm:ss"
                      )}
                    </p>
                  </div>
                  <div className="color-card-middle">
                    <div>
                      <p>Invoice Issue Date</p>
                      <p>
                        {moment(invoice.invoiceIssueDate).format(
                          "DD MMM YYYY HH:mm:ss"
                        )}
                      </p>
                    </div>
                    <div>
                      <p>Policy Issue Date</p>
                      <p>
                        {moment(invoice.policyIssueDate).format(
                          "DD MMM YYYY HH:mm:ss"
                        )}
                      </p>
                    </div>

                    <hr />

                    <div>
                      <p>Rate</p>
                      <p>
                        {invoice.rate
                          ? `${invoice.currencyCodeForPremium} ${handleCurrency(
                              invoice.rate
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p>Policy Cost</p>
                      <p>
                        {invoice.policyCost
                          ? `${invoice.currencyCodeForPremium} ${handleCurrency(
                              invoice.policyCost
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p>Stamp Duty</p>
                      <p>
                        {invoice.stampDuty
                          ? `${invoice.currencyCodeForPremium} ${handleCurrency(
                              invoice.stampDuty
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p>Admin Cost</p>
                      <p>
                        {invoice.adminCost
                          ? `${invoice.currencyCodeForPremium} ${handleCurrency(
                              invoice.adminCost
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p>Discount</p>
                      <p>
                        {invoice.discount
                          ? `${invoice.currencyCodeForPremium} ${handleCurrency(
                              invoice.discount
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div>
                      <p>Premium</p>
                      <p>
                        {`${invoice.currencyCodeForPremium} ${handleCurrency(
                          invoice.premium
                        )}`}
                      </p>
                    </div>

                    <hr />

                    <div>
                      <p>Net Premium</p>
                      <p>
                        {`${invoice.currencyCodeForPremium} ${handleCurrency(
                          invoice.netPremium
                        )}`}
                      </p>
                    </div>
                  </div>

                  <button
                    className="color-card-bottom"
                    onClick={() =>
                      handleDownload(invoice.id, invoice.invoiceNumber)
                    }
                    disabled={invoice.files && !invoice.files.invoice}
                  >
                    {downloadLoading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <span>DOWNLOAD INVOICE</span>
                    )}
                  </button>
                </div>
              </MDBCol>
            </MDBRow>

            <div className="mt-5 d-flex">
              <Link to="/invoice" onClick={() => window.scrollTo(0, 0)}>
                <Button text="BACK" secondary back />
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
