export const setWaitingForm = (payload) => {
  return {
    type: "SET_WAITING_FORM",
    payload,
  };
};

export const setPaidForm = (payload) => {
  return {
    type: "SET_PAID_FORM",
    payload,
  };
};

export const setIsActive = (payload) => {
  return {
    type: "SET_IS_ACTIVE",
    payload,
  };
};
