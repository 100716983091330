import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MDBContainer } from "mdbreact";
import Home from "./pages/Home/Home";
import Invoice from "./pages/Invoice/Invoice";
import InvoiceDetail from "./pages/InvoiceDetail/InvoiceDetail";
import Summary from "./pages/Summary/Summary";
import Paid from "./pages/Paid/Paid";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NoRoute from "./pages/404/404";

function App() {
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");

    setToken(token);
    setUser(user);
  }, []);

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <Router>
      <Header />

      <main>
        <MDBContainer>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/invoice">
              {token && user ? <Invoice /> : <NoRoute />}
            </Route>

            <Route path="/invoice/:id">
              {token && user ? <InvoiceDetail /> : <NoRoute />}
            </Route>

            <Route path="/summary">
              {token && user ? <Summary /> : <NoRoute />}
            </Route>

            <Route path="/paid">{token && user ? <Paid /> : <NoRoute />}</Route>

            <Route>
              <NoRoute />
            </Route>
          </Switch>
        </MDBContainer>
      </main>

      <Footer />
    </Router>
  );
}

export default App;
