const initialState = {
  waitingForm: {
    insured: "",
    companyName: "",
    invoiceNumber: "",
    placingSlipNo: "",
    productCode: "",
    minSumInsured: "",
    maxSumInsured: "",
    minPremium: "",
    maxPremium: "",
    minPolicyIssueDate: "",
    maxPolicyIssueDate: "",
    minInvoiceIssueDate: "",
    maxInvoiceIssueDate: "",
    minSchedulePaymentDate: "",
    maxSchedulePaymentDate: "",
    minPaymentDate: "",
    maxPaymentDate: "",
    certificateNumber: "",
  },
};

const waitingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WAITING_FORM":
      return {
        ...state,
        waitingForm: action.payload,
      };
    default:
      return state;
  }
};

export default waitingReducer;
