const initialState = {
  dataSummary: {
    invoiceList: [],
    totalAmount: 0,
  },
  invoiceNumbers: [],
  invoiceTotal: 0,
  paymentLink: "",
};

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_SUMMARY":
      return {
        ...state,
        dataSummary: action.payload,
      };
    case "SET_INVOICE_NUMBERS":
      return {
        ...state,
        invoiceNumbers: action.payload,
      };
    case "SET_INVOICE_TOTAL":
      return {
        ...state,
        invoiceTotal: action.payload,
      };
    case "SET_PAYMENT_LINK":
      return {
        ...state,
        paymentLink: action.payload,
      };
    case "RESET_DATA_SUMMARY":
      return (state = initialState);
    default:
      return state;
  }
};

export default summaryReducer;
