import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";

export default function Home() {
  const history = useHistory();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  useEffect(() => {
    const user = {
      token: query.get("token"),
      userCode: query.get("user"),
    };

    if (user.token && user.userCode) {
      sessionStorage.setItem("token", user.token);
      sessionStorage.setItem("user", user.userCode);
    }

    setTimeout(() => {
      history.push("/invoice");
    }, 1000);
  }, [history, query]);

  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <Loading />
    </div>
  );
}
