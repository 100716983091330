import { useEffect, useState, useRef } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import axiosGet from "../../helpers/axiosGet";
import "./Invoice.css";
import Loading from "../../components/Loading";
import Button from "../../components/Button/Button";
import Jumbotron from "../../components/Jumbotron/Jumbotron";
import DataTable from "../../components/DataTable/DataTable";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import handleCurrency from "../../components/CurrencyFormat";
import { useDispatch, useSelector } from "react-redux";
import { setIsActive } from "../../store/invoice/actions";
import {
  setInvoiceNumbers,
  setInvoiceTotal,
  setDataSummary,
} from "../../store/summary/actions";
import WaitingForm from "../../components/Forms/WaitingForm";
import PaidForm from "../../components/Forms/PaidForm";

export default function Invoice() {
  const scroll = useRef(null);
  const executeScroll = () => scroll.current.scrollIntoView();

  const dispatch = useDispatch();
  const history = useHistory();
  const isActive = useSelector((state) => state.table.active);
  const invoiceNumbers = useSelector((state) => state.summary.invoiceNumbers);

  const links = [
    {
      name: `waiting`,
      text: "Menunggu Pembayaran",
    },
    {
      name: `paid`,
      text: "Pembayaran Selesai",
    },
  ];

  const [waitingValues, setWaitingValues] = useState(null);
  const [paidValues, setPaidValues] = useState(null);

  const [loading, setLoading] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [rows, setRows] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("waiting");

  useEffect(() => {
    if (isActive === "paid") {
      setActive("paid");
      fetchPaidInvoices();
    } else {
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckoutLoading = (value) => {
    setCheckoutLoading(value);
  };

  const handleActive = (name) => {
    setActive(name);
    dispatch(setIsActive(name));

    if (name === "waiting") {
      fetchInvoices();
    } else {
      fetchPaidInvoices();
    }
  };

  const handleRows = (param) => {
    setRows(param);
  };

  const searchHandler = (values) => {
    if (active === "waiting") {
      fetchInvoices(1, values);
      setWaitingValues(values);
    } else {
      fetchPaidInvoices(1, values);
      setPaidValues(values);
    }
  };

  const resetHandler = () => {
    if (active === "waiting") {
      fetchInvoices();
      setWaitingValues(null);
    } else {
      fetchPaidInvoices();
      setPaidValues(null);
    }
  };

  const fetchInvoices = (page, values, rows) => {
    setLoading(true);

    axiosGet({
      url: `/invoice/page/${page ? page : 1}?insured=${
        values ? values.insured : ""
      }&companyName=${values ? values.companyName : ""}&invoiceNumber=${
        values ? values.invoiceNumber : ""
      }&placingSlipNo=${values ? values.placingSlipNo : ""}&productCode=${
        values ? values.productCode : ""
      }&minSumInsured=${values ? values.minSumInsured : ""}&maxSumInsured=${
        values ? values.maxSumInsured : ""
      }&minPremium=${values ? values.minPremium : ""}&maxPremium=${
        values ? values.maxPremium : ""
      }&minPolicyIssueDate=${
        values ? values.minPolicyIssueDate : ""
      }&maxPolicyIssueDate=${
        values ? values.maxPolicyIssueDate : ""
      }&minInvoiceIssueDate=${
        values ? values.minInvoiceIssueDate : ""
      }&maxInvoiceIssueDate=${
        values ? values.maxInvoiceIssueDate : ""
      }&minSchedulePaymentDate=${
        values ? values.minSchedulePaymentDate : ""
      }&maxSchedulePaymentDate=${
        values ? values.maxSchedulePaymentDate : ""
      }&minPaymentDate=${values ? values.minPaymentDate : ""}&maxPaymentDate=${
        values ? values.maxPaymentDate : ""
      }&certificateNumber=${
        values ? values.certificateNumber : ""
      }&status=0&maxCount=${rows ? rows : waitingRowsPerPage}`,
      callback: (res) => {
        setLoading(false);

        setInvoices(res.data.data);

        const currentInvs = res.data.data.map((el) => {
          return { ...el, checked: false };
        });

        if (invoiceNumbers.length) {
          const newInvs = currentInvs.map((inv) => {
            let result = invoiceNumbers.filter((list) => list.id === inv.id);
            if (result.length) {
              inv.checked = true;
            }
            return inv;
          });
          setRows(newInvs);
        } else {
          setRows(currentInvs);
        }
        setWaitingPage(res.data.page - 1);
        setWaitingTotalPage(res.data.totalPage);
      },
      errorCallback: (err) => {
        console.log(err);
        setLoading(false);
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("user");
        history.push("/404");
      },
    });
  };

  const fetchPaidInvoices = (page, values) => {
    setLoading(true);

    axiosGet({
      url: `/invoice/page/${page ? page : 1}?insured=${
        values ? values.insured : ""
      }&companyName=${values ? values.companyName : ""}&invoiceNumber=${
        values ? values.invoiceNumber : ""
      }&placingSlipNo=${values ? values.placingSlipNo : ""}&productCode=${
        values ? values.productCode : ""
      }&minSumInsured=${values ? values.minSumInsured : ""}&maxSumInsured=${
        values ? values.maxSumInsured : ""
      }&minPremium=${values ? values.minPremium : ""}&maxPremium=${
        values ? values.maxPremium : ""
      }&minPolicyIssueDate=${
        values ? values.minPolicyIssueDate : ""
      }&maxPolicyIssueDate=${
        values ? values.maxPolicyIssueDate : ""
      }&minInvoiceIssueDate=${
        values ? values.minInvoiceIssueDate : ""
      }&maxInvoiceIssueDate=${
        values ? values.maxInvoiceIssueDate : ""
      }&minSchedulePaymentDate=${
        values ? values.minSchedulePaymentDate : ""
      }&maxSchedulePaymentDate=${
        values ? values.maxSchedulePaymentDate : ""
      }&minPaymentDate=${values ? values.minPaymentDate : ""}&maxPaymentDate=${
        values ? values.maxPaymentDate : ""
      }&certificateNumber=${
        values ? values.certificateNumber : ""
      }&status=100&maxCount=`,
      callback: (res) => {
        setLoading(false);
        setPaidInvoices(res.data.data);
        setPaidPage(res.data.page - 1);
        setPaidTotalPage(res.data.totalPage);
      },
      errorCallback: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const [waitingPage, setWaitingPage] = useState(0);
  const [waitingRowsPerPage, setWaitingRowsPerPage] = useState(10);
  const [waitingTotalPage, setWaitingTotalPage] = useState(0);
  const [paidPage, setPaidPage] = useState(0);
  const [paidTotalPage, setPaidTotalPage] = useState(0);

  const handleWaitingPage = (value) => {
    setWaitingPage(value.selected);

    let page = value.selected + 1;
    fetchInvoices(page, waitingValues);
    executeScroll();
  };

  const handleWaitingRowsPerPage = (value) => {
    setWaitingRowsPerPage(value);
    fetchInvoices(1, waitingValues, value);
    executeScroll();
  };

  const handlePaidPage = (value) => {
    setPaidPage(value.selected);

    let page = value.selected + 1;
    fetchPaidInvoices(page, paidValues);
    executeScroll();
  };

  return (
    <>
      <Jumbotron />

      {checkoutLoading ? (
        <Loading />
      ) : (
        <>
          <div className="border rounded mt-5">
            <MDBContainer
              className="px-4 py-2"
              onClick={() => setOpen(!open)}
              style={{ cursor: "pointer" }}
            >
              <MDBRow>
                <MDBCol size="6" middle>
                  <p className="mb-0">Search</p>
                </MDBCol>
                <MDBCol size="6" className="text-right">
                  <MDBIcon icon={open ? "angle-up" : "angle-down"} size="lg" />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            {open &&
              (active === "waiting" ? (
                <WaitingForm onSearch={searchHandler} onReset={resetHandler} />
              ) : (
                <PaidForm onSearch={searchHandler} onReset={resetHandler} />
              ))}
          </div>

          <div className="mt-5" ref={scroll}>
            <MDBRow>
              {links.map((link, index) => {
                return (
                  <MDBCol key={link.name}>
                    <MDBContainer>
                      <MDBRow end={index === 0}>
                        <div
                          className="link"
                          onClick={() => handleActive(link.name)}
                        >
                          <p className="link-text">{link.text}</p>
                          {active === link.name ? (
                            <hr className="divider" />
                          ) : (
                            ""
                          )}
                        </div>
                      </MDBRow>
                    </MDBContainer>
                  </MDBCol>
                );
              })}
            </MDBRow>
          </div>

          <div className="mt-5">
            {loading ? (
              <Loading />
            ) : active === "waiting" ? (
              <Waiting
                invoices={invoices}
                rows={rows}
                onRowsChange={handleRows}
                active={active}
                page={waitingPage}
                onPageChange={handleWaitingPage}
                totalPage={waitingTotalPage}
                rowsPerPage={waitingRowsPerPage}
                onRowsPerPageChange={handleWaitingRowsPerPage}
                onLoading={handleCheckoutLoading}
              />
            ) : (
              <Paid
                invoices={paidInvoices}
                active={active}
                page={paidPage}
                onPageChange={handlePaidPage}
                totalPage={paidTotalPage}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

const Waiting = ({
  invoices,
  rows,
  onRowsChange,
  active,
  page,
  onPageChange,
  totalPage,
  rowsPerPage,
  onRowsPerPageChange,
  onLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { invoiceNumbers, invoiceTotal } = useSelector(
    (state) => state.summary
  );
  const [allChecked, setAllChecked] = useState(false);

  const handleChecked = (inv) => {
    const checkedRows = rows.map((row) => {
      return {
        ...row,
        checked: row.invoiceNumber === inv.invoiceNumber ? true : row.checked,
      };
    });
    onRowsChange(checkedRows);
    handleCheckedInvs(checkedRows);
  };

  const handleCheckedInvs = (invs) => {
    const checked = invs.filter((row) => row.checked);

    let currentInvNumbs = invoiceNumbers;
    let newInvNumbs = checked;

    const mergedInvNumbs = currentInvNumbs.concat(
      newInvNumbs.filter((el) =>
        currentInvNumbs.every((curr) => curr.id !== el.id)
      )
    );

    dispatch(setInvoiceNumbers(mergedInvNumbs));

    const totalInvsAmount = mergedInvNumbs
      .map((inv) => Number(inv.premium))
      .reduce((total, num) => {
        return total + num;
      });
    dispatch(setInvoiceTotal(totalInvsAmount));
  };

  const handleUnchecked = (inv) => {
    const uncheckedRows = rows.map((row) => {
      return {
        ...row,
        checked: row.invoiceNumber === inv.invoiceNumber ? false : row.checked,
      };
    });
    onRowsChange(uncheckedRows);
    handleUncheckedInvs(inv);
  };

  const handleUncheckedInvs = (invs) => {
    let currentInvNumbs = invoiceNumbers;
    let mergedInvNumbs = [];
    let totalInvsAmount = 0;

    if (Array.isArray(invs)) {
      const current = currentInvNumbs.map((inv) => inv.id);
      const toRemove = invs.map((inv) => inv.id);

      const removedInvs = current.filter((id) => !toRemove.includes(id));
      if (removedInvs.length) {
        mergedInvNumbs = currentInvNumbs.filter((curr) =>
          removedInvs.includes(curr.id)
        );
        let total = mergedInvNumbs
          .map((inv) => Number(inv.premium))
          .reduce((total, num) => {
            return total + num;
          });
        totalInvsAmount = total;
      }
    } else {
      mergedInvNumbs = currentInvNumbs.filter((curr) => curr.id !== invs.id);
      totalInvsAmount = invoiceTotal - Number(invs.premium);
    }

    dispatch(setInvoiceNumbers(mergedInvNumbs));
    dispatch(setInvoiceTotal(totalInvsAmount));
  };

  const handleCheckbox = (e, inv) => {
    if (e.target.checked) {
      handleChecked(inv);
    } else {
      handleUnchecked(inv);
    }
  };

  const handleAllChecked = (e) => {
    setAllChecked(e.target.checked);

    const newRows = rows.map((row) => {
      return { ...row, checked: e.target.checked ? true : false };
    });
    onRowsChange(newRows);

    if (e.target.checked) {
      handleCheckedInvs(newRows);
    } else {
      handleUncheckedInvs(newRows);
    }
  };

  const handleCheckout = () => {
    const invNumbs = invoiceNumbers.map((inv) => inv.invoiceNumber);

    if (invNumbs.length) {
      window.scrollTo(0, 0);
      onLoading(true);

      axiosGet({
        url: `/invoice/summary-paid?invoiceNumbers=${invNumbs}`,
        callback: (res) => {
          onLoading(false);

          dispatch(
            setDataSummary({
              invoiceList: res.data.invoiceList,
              totalAmount: res.data.totalAmount,
            })
          );

          history.push("/summary");
        },
        errorCallback: (err) => {
          onLoading(false);
          console.log(err);
        },
      });
    }
  };

  return (
    <>
      {invoices.length ? (
        <>
          <div className="p-4 border rounded text-grey">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id={`tableCheckboxAll${page + 1}`}
                onChange={handleAllChecked}
                checked={allChecked}
              />
              <label
                className="custom-control-label font-weight-bold"
                htmlFor={`tableCheckboxAll${page + 1}`}
              >
                Pilih Semua
              </label>
            </div>

            <DataTable
              invoices={rows}
              active={active}
              onChecked={handleCheckbox}
              allChecked={allChecked}
            />
          </div>

          <div className="mt-5 d-flex align-items-end justify-content-between">
            <div>
              <p>Rows per page</p>
              <select
                className="browser-default custom-select"
                style={{ width: 75 }}
                value={rowsPerPage}
                onChange={(e) => onRowsPerPageChange(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>
            </div>

            {totalPage > 1 && (
              <ReactPaginate
                previousLabel={<MDBIcon icon="chevron-left" />}
                nextLabel={<MDBIcon icon="chevron-right" />}
                breakLabel={"..."}
                pageCount={totalPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                forcePage={page}
                onPageChange={onPageChange}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>

          <div className="mt-5 total-invoice">
            <div>
              <p>Total Invoice</p>
              <p>{invoiceNumbers.length} </p>
            </div>
            <div>
              <p>Total Pembayaran</p>
              <p>{`(IDR) ${handleCurrency(invoiceTotal)}`}</p>
            </div>
          </div>
        </>
      ) : (
        <div className="font-weight-bold text-center">NO DATA</div>
      )}

      <div className="mt-5 d-flex justify-content-end">
        <Button text="CHECKOUT" onClick={handleCheckout} />
      </div>
    </>
  );
};

const Paid = ({ invoices, active, page, totalPage, onPageChange }) => {
  return (
    <>
      {invoices.length ? (
        <>
          <div className="p-4 border rounded text-grey">
            <DataTable invoices={invoices} active={active} />
          </div>

          <div className="mt-5 d-flex justify-content-end">
            {totalPage > 1 && (
              <ReactPaginate
                previousLabel={<MDBIcon icon="chevron-left" />}
                nextLabel={<MDBIcon icon="chevron-right" />}
                breakLabel={"..."}
                pageCount={totalPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                forcePage={page}
                onPageChange={onPageChange}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>
        </>
      ) : (
        <div className="font-weight-bold text-center">NO DATA</div>
      )}
    </>
  );
};
