import React from "react";
import { MDBIcon } from "mdbreact";
import "./Button.css";

export default function Button({
  loading,
  text,
  type,
  icon,
  onClick,
  secondary,
  small,
  back,
}) {
  const classes = (secondary, small) => {
    const rootClass = "rootButton";
    const secondaryClass = "secondary";
    const smallClass = "rootButton small";

    return secondary
      ? small
        ? `${smallClass} ${secondaryClass}`
        : `${rootClass} ${secondaryClass}`
      : small
      ? `${smallClass}`
      : `${rootClass} `;
  };

  return (
    <button
      disabled={loading}
      type={type}
      className={classes(secondary, small)}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          {back && <MDBIcon icon="arrow-left" className="mr-2"></MDBIcon>}
          <span>{text}</span>
          {!back && icon && <MDBIcon icon={icon} className="ml-2"></MDBIcon>}
        </>
      )}
    </button>
  );
}
