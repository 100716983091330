import * as dotenv from "dotenv";

dotenv.config({
  path: process.env.ENV_FILE,
  encoding: "utf8",
});

const constants = {};

constants.DEFAULT_URL =
  process.env.REACT_APP_DEFAULT_URL ||
  "https://beta-api-invoice.polismall.id";

constants.HTTP_STATUS_SUCCESS = 200;

export default constants;
