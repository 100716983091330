import { createStore } from "redux";
import rootReducers from "./rootReducers";

function saveToSessionStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    sessionStorage.setItem("persistantState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromSessionStorage() {
  try {
    const serialisedState = sessionStorage.getItem("persistantState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

const store = createStore(rootReducers, loadFromSessionStorage());

store.subscribe(() => saveToSessionStorage(store.getState()));

export default store;
