import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import products from "../../components/ProductCode.json";
import Button from "../../components/Button/Button";

export default function WaitingForm({ onSearch, onReset }) {
  const waitingForm = useSelector((state) => state.waiting.waitingForm);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: waitingForm,
  });

  const resetHandler = () => {
    reset({
      insured: "",
      companyName: "",
      invoiceNumber: "",
      placingSlipNo: "",
      productCode: "",
      minSumInsured: "",
      maxSumInsured: "",
      minPremium: "",
      maxPremium: "",
      minPolicyIssueDate: "",
      maxPolicyIssueDate: "",
      minInvoiceIssueDate: "",
      maxInvoiceIssueDate: "",
      minSchedulePaymentDate: "",
      maxSchedulePaymentDate: "",
      minPaymentDate: "",
      maxPaymentDate: "",
      certificateNumber: "",
    });

    onReset();
  };

  return (
    <form onSubmit={handleSubmit(onSearch)} onReset={resetHandler}>
      <MDBContainer className="border-top p-4 grey-text">
        <MDBRow className="create-row-gap">
          <MDBCol md="4">
            <label>Insured</label>
            <input
              placeholder="Insured"
              className="form-control"
              {...register("insured")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Company Name</label>
            <input
              placeholder="Company Name"
              className="form-control"
              {...register("companyName")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Invoice Number</label>
            <input
              placeholder="Invoice Number"
              className="form-control"
              {...register("invoiceNumber")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Placing Slip Number</label>
            <input
              placeholder="Placing Slip Number"
              className="form-control"
              {...register("placingSlipNo")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Product Code</label>
            <select
              className="browser-default custom-select"
              {...register("productCode")}
            >
              <option value="">ALL</option>
              {products.map((product) => {
                return (
                  <option key={product.name} value={product.value}>
                    {product.name}
                  </option>
                );
              })}
            </select>
          </MDBCol>
          <MDBCol md="4">
            <label>Min Sum Insured</label>
            <input
              type="number"
              placeholder="Min Sum Insured"
              className="form-control"
              {...register("minSumInsured")}
              onKeyDown={(e) => {
                if (
                  isNaN(e.key) &&
                  !(
                    e.key === "Backspace" ||
                    e.key === "Tab" ||
                    e.key === "Enter"
                  )
                )
                  e.preventDefault();
              }}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Sum Insured</label>
            <input
              type="number"
              placeholder="Max Sum Insured"
              className="form-control"
              {...register("maxSumInsured")}
              onKeyDown={(e) => {
                if (
                  isNaN(e.key) &&
                  !(
                    e.key === "Backspace" ||
                    e.key === "Tab" ||
                    e.key === "Enter"
                  )
                )
                  e.preventDefault();
              }}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Min Premium</label>
            <input
              type="number"
              placeholder="Min Premium"
              className="form-control"
              {...register("minPremium")}
              onKeyDown={(e) => {
                if (
                  isNaN(e.key) &&
                  !(
                    e.key === "Backspace" ||
                    e.key === "Tab" ||
                    e.key === "Enter"
                  )
                )
                  e.preventDefault();
              }}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Premium</label>
            <input
              type="number"
              placeholder="Max Premium"
              className="form-control"
              {...register("maxPremium")}
              onKeyDown={(e) => {
                if (
                  isNaN(e.key) &&
                  !(
                    e.key === "Backspace" ||
                    e.key === "Tab" ||
                    e.key === "Enter"
                  )
                )
                  e.preventDefault();
              }}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Min Policy Issue Date</label>
            <input
              placeholder="Min Policy Issue Date"
              type="date"
              className="form-control"
              {...register("minPolicyIssueDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Policy Issue Date</label>
            <input
              placeholder="Max Policy Issue Date"
              type="date"
              className="form-control"
              {...register("maxPolicyIssueDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Min Invoice Issue Date</label>
            <input
              placeholder="Min Invoice Issue Date"
              type="date"
              className="form-control"
              {...register("minInvoiceIssueDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Invoice Issue Date</label>
            <input
              placeholder="Max Invoice Issue Date"
              type="date"
              className="form-control"
              {...register("maxInvoiceIssueDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Min Schedule Payment Date</label>
            <input
              placeholder="Min Schedule Payment Date"
              type="date"
              className="form-control"
              {...register("minSchedulePaymentDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Schedule Payment Date</label>
            <input
              placeholder="Max Schedule Payment Date"
              type="date"
              className="form-control"
              {...register("maxSchedulePaymentDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Min Payment Date</label>
            <input
              placeholder="Min Payment Date"
              type="date"
              className="form-control"
              {...register("minPaymentDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Max Payment Date</label>
            <input
              placeholder="Max Payment Date"
              type="date"
              className="form-control"
              {...register("maxPaymentDate")}
            />
          </MDBCol>
          <MDBCol md="4">
            <label>Certificate Number</label>
            <input
              placeholder="Certificate Number"
              className="form-control"
              {...register("certificateNumber")}
            />
          </MDBCol>

          <MDBCol md="12">
            <MDBContainer>
              <MDBRow end>
                <Button text="RESET" type="reset" small secondary />
                <div className="ml-3">
                  <Button text="SEARCH" icon="search" type="submit" small />
                </div>
              </MDBRow>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </form>
  );
}
