const initialState = {
  active: "waiting",
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IS_ACTIVE":
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};

export default tableReducer;
