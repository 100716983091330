export const setDataSummary = (payload) => {
  return {
    type: "SET_DATA_SUMMARY",
    payload,
  };
};

export const setInvoiceNumbers = (payload) => {
  return {
    type: "SET_INVOICE_NUMBERS",
    payload,
  };
};

export const setInvoiceTotal = (payload) => {
  return {
    type: "SET_INVOICE_TOTAL",
    payload,
  };
};

export const setPaymentLink = (payload) => {
  return {
    type: "SET_PAYMENT_LINK",
    payload,
  };
};

export const resetDataSummary = (payload) => {
  return {
    type: "RESET_DATA_SUMMARY",
    payload,
  };
};
