import React from "react";
import "./Jumbotron.css";
import Man from "../../img/man.svg";
import Mask from "../../img/mask.svg";
import Flower from "../../img/flower.svg";

export default function Jumbotron() {
  return (
    <div className="root">
      <div className="glass">
        <img src={Mask} alt="mask" className="mask" />
        <div>
          <h1>
            POLISMALL <br /> Invoice
          </h1>
          <p>We provide Insurance to let you at ease.</p>
        </div>
      </div>
      <img src={Man} alt="man" className="man" />
      <img src={Flower} alt="flower" className="flower" />
    </div>
  );
}
